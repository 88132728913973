<template>
   <Header/>
   <RouterView />
   <bottom-bar class="bottom__bar" @select="onBottomBarSelect" />
   <Footer/>
   <Spinner/>
   <ContactUs v-if="isModalShow" @close-modal="modalHide" />
</template>

<script>
import { mapGetters } from "vuex";
import {defineComponent} from "vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import ContactUs from '@/components/ContactUs.vue';
import BottomBar from "@/components/BottomBar.vue";
import '../public/style.scss'
import { RouterView } from "vue-router";
import Spinner from "@/components/Spinner.vue";
export default defineComponent({
  components: {Header, Footer, ContactUs, RouterView, Spinner, BottomBar},
  computed: {
    ...mapGetters("main", {
      isModalShow: "getShowModal",
    }),
  },
  methods: {
    onBottomBarSelect(val) {
      const goAway = (link) => { window.location.href = link; }

      switch (val) {
        case 'try':
          this.$store.commit("main/setModalShow", true);
          break;
        case 'contact':
          goAway('https://wa.me/77071110950');
          break;
        case 'auth':
          goAway('https://trustme.kz/contract/auth/');
          break;
        default:
          console.warn(`The bottom bar has no action for item '${val}'`);
      }
    },
    modalHide() {
      setTimeout(() => {
        this.$store.commit("main/setModalShow", false);
      }, 300);
    },
  },
})
</script>

<style>
#app {
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #EDF2F4;
}
.bottom__bar{
  display: none;
}
@media only screen and (max-width: 992px) {
 .bottom__bar {
  display: block;
 }
}
</style>
